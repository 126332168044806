import { Button, Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'
import { IEvent } from 'src/api'
import { DetailsSection } from 'src/components/details-section'
import { eventCardRowSpacing, eventCardSpacing } from 'src/layouts/spacing'
import { scrollableRowStyles } from 'src/lib/scrollable-row-styles'
import { useBoolean } from 'src/lib/use-boolean'
import { EventCardFindTickets } from './event-card'

type Props = {
  events: Array<IEvent>
  title: string
  withDivider?: boolean
}

const maxVisibleEvents = 3 as const

export const EventsSectionLoadMore = ({
  events,
  title,
  withDivider = true,
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const isShowAll = useBoolean(!(events.length > maxVisibleEvents))

  useEffect(() => {
    if (events.length > maxVisibleEvents) {
      isShowAll.setFalse()
      return
    }
    isShowAll.setTrue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events.length])

  const eventsLimited = isShowAll.isTrue
    ? events
    : events.slice(0, maxVisibleEvents)

  return (
    <DetailsSection withDivider={withDivider} title={title}>
      {isMobile ? (
        <Box>
          <Stack
            spacing={eventCardSpacing}
            direction="row"
            sx={scrollableRowStyles}
          >
            {events.map((event) => (
              <EventCardFindTickets event={event} key={event.eventId} />
            ))}
          </Stack>
        </Box>
      ) : (
        <Stack spacing={{ lg: 3, xl: 4 }}>
          <Box>
            <Grid
              container
              columnSpacing={eventCardSpacing}
              rowSpacing={eventCardRowSpacing}
            >
              {eventsLimited.map((event) => (
                <Grid item xs={4} key={event.eventId}>
                  <EventCardFindTickets event={event} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {!isShowAll.isTrue && (
            <Button
              variant="secondary"
              size="medium"
              fullWidth
              onClick={isShowAll.setTrue}
            >
              {t('load_more')}
            </Button>
          )}
        </Stack>
      )}
    </DetailsSection>
  )
}
