import { formatInTimeZone } from 'date-fns-tz'
import { IApplicationLocale, localeToDateFnsLocale } from 'src/app/locale'

export const formatInTimeZoneLocalized = ({
  date,
  timezone,
  format,
  locale,
}: {
  date: string
  timezone: string
  format: string
  locale: IApplicationLocale
}) => {
  const preparedDate = new Date(date)

  return formatInTimeZone(preparedDate, timezone, format, {
    locale: localeToDateFnsLocale[locale],
  })
}
