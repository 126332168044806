import { Translate } from 'next-translate'
import { isNonEmptyString } from '../types'

export const formatCityCountry = ({
  city,
  country,
}: {
  city: string
  country: string
}) => `${city ? `${city}, ` : ''}${country}`

export const formatVatNumber = (vatNumber: string) =>
  (vatNumber.match(/.{1,3}/g) ?? []).join(' ')

export const formatStreet = ({
  street,
  streetNumber,
}: {
  street: string
  streetNumber: string
}) => {
  return [street, streetNumber].filter(isNonEmptyString).join(' ')
}

export const formatLeft = ({
  label,
  leftNumber,
  t,
}: {
  label: string
  leftNumber: number
  t: Translate
}) => {
  return `${label} (${t('number_left', { number: leftNumber })})`
}

export const formatPrice = ({
  currency,
  price,
  t,
}: {
  currency: string
  price: number
  t: Translate
}) => {
  return `${t(`currency_sign_${currency}`)} ${price}`
}

export const formatAgeLimit = (ageLimit: number, t: Translate) =>
  ageLimit ? t('age_limit_value', { value: ageLimit }) : t('no_age_limit')
