/* eslint-disable fp/no-mutating-methods */
import { ILiveOrderCombinedBucket, IEventDetails } from 'src/api'

export const sendPurchaseAnalytics = ({
  orderSummary,
  currency,
  organizationName,
  eventName,
}: {
  orderSummary: ILiveOrderCombinedBucket
  currency: string
  organizationName: string
  eventName: string
}) => {
  window.dataLayer.push({
    event: 'purchase',
    order: {
      transaction_id: orderSummary.orderId,
      value: orderSummary.totalPrice,
      affiliation: organizationName,
      tax: orderSummary.totalFee,
      items: orderSummary.ticketPricesInfoResponse.map((ticket) => ({
        item_id: ticket.ticketSeatId,
        price: ticket.price,
        quantity: ticket.quantity,
        item_category: 'ticket',
        item_category2: ticket.variationType,
      })),
      event_name: eventName,
      currency,
    },
  })
}

export const sendEventDetailsPageViewAnalytics = (event: IEventDetails) => {
  window.dataLayer.push({
    event: 'event_details_page_view',
    eventName: event.name,
    eventType: event.eventType.name,
    eventId: event.eventId,
    organiserName: event.organization.name,
    organiserId: event.organization.organizationId,
  })
}

export type AnalyticEntity = {
  id: string
  entityType: string
  name: string
}

export const sendShareAnalytics = ({
  id,
  entityType,
  name,
}: AnalyticEntity) => {
  window.dataLayer.push({
    event: 'share',
    entity: {
      id,
      entityType,
      name,
    },
  })
}

export const sendAddToFavoritesAnalytics = ({
  id,
  entityType,
  name,
}: AnalyticEntity) => {
  window.dataLayer.push({
    event: 'add_to_favorites',
    entity: {
      id,
      entityType,
      name,
    },
  })
}
