import {
  Box,
  Button,
  Checkbox,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { LikeCheckedIcon } from './icons/like-checked'
import { useLocale } from 'src/lib/use-locale'
import { useFavoriteEvent } from '../use-favorite'
import { NextLink } from 'src/components/next-link'
import { formatEventDate } from '../utils/event-format-date'
import { formatEventPlace } from '../utils/event-format-display'
import { EventPriceType, IEvent } from 'src/api'
import { useIsSessionLoaded } from 'src/lib/use-is-session-loaded'
import { pages } from 'src/app/pages'
import { LikeBlurredBackground } from 'src/assets/icons'
import { DialogSignIn } from 'src/modules/sign-in/dialog-sign-in'
import { ResponsiveImage, getLogoSrcSet } from 'src/components/responsive-image'
import { isSome, toNullable } from 'fp-ts/lib/Option'

const textOverflowProps = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const

type Props = {
  event: IEvent
}

export const EventCardFindTickets = ({ event }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { locale } = useLocale()
  const isSessionLoaded = useIsSessionLoaded()

  const isEventWithoutTickets =
    event.priceType === EventPriceType.FREE && !event.ticketsExist

  const { isFavorite, onAddFavorite, dialogUnauthenticated } = useFavoriteEvent(
    {
      eventId: event.eventId,
      isFavoriteDefault: event.favourite,
      eventName: event.name,
    },
  )

  return (
    <>
      {dialogUnauthenticated.isTrue && (
        <DialogSignIn onClose={dialogUnauthenticated.setFalse} />
      )}
      <NextLink
        href={pages['/events/[eventSlug]']({ eventSlug: event.eventSlug })}
        color="common.black"
        underline="none"
      >
        <Stack
          width={{ xs: 277, sm: 304, lg: 305, xl: 342 }}
          sx={{
            backgroundColor: theme.palette.common.white,
            boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.15)',
            borderRadius: '12px',
            flexShrink: 0,
            transition: 'transform 0.3s',
            willChange: 'transform',
            '&:hover': {
              transform: 'scale(1.03)',
            },
          }}
        >
          <Box
            height={{ xs: 190, lg: 216 }}
            sx={{
              position: 'relative',
              '& .eventImage': {
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                backgroundColor:
                  toNullable(event.logoSetting)?.logoBackgroundColor ??
                  undefined,
              },
            }}
          >
            {isSome(event.logoSetting) ? (
              <ResponsiveImage
                alt={event.name}
                srcSet={getLogoSrcSet(event.logoSetting.value.logo.resizedUrls)}
                originalSizeUrl={event.logoSetting.value.logo.url}
                className="eventImage"
                objectFit="contain"
              />
            ) : (
              <ResponsiveImage
                alt={event.name}
                originalSizeUrl={event.mainImage.url}
                srcSet={{ MOBILE: event.mainImage.resizedUrls.MEDIUM }}
                className="eventImage"
              />
            )}

            {isSessionLoaded && (
              <Checkbox
                icon={<LikeBlurredBackground />}
                checkedIcon={<LikeCheckedIcon />}
                onClick={(event) => {
                  event.stopPropagation()
                }}
                onChange={(event) => {
                  onAddFavorite(event.target.checked)
                }}
                sx={{ p: 2, position: 'absolute', top: 0, right: 0 }}
                checked={isFavorite}
              />
            )}
          </Box>
          <Stack px={2} py={2.5} spacing={{ xs: 2.5, lg: 3 }}>
            <Stack spacing={1.25}>
              <Stack spacing={0.25}>
                <Typography
                  variant="tinyTextSemiBold"
                  sx={{
                    ...textOverflowProps,
                  }}
                >
                  {event.eventType.name}
                </Typography>
                <Typography
                  component="div"
                  variant="h3ExtraBold"
                  sx={{
                    ...textOverflowProps,
                  }}
                >
                  {event.name}
                </Typography>
              </Stack>
              <Stack spacing={{ xs: 1.5, lg: 1, xl: 0.5 }}>
                <Stack spacing={0.25}>
                  <Typography
                    variant="smallTextSemiBold"
                    color="secondary8.main"
                    component="div"
                  >
                    {t('date')}
                  </Typography>
                  <Typography variant="smallTextSemiBold" component="div">
                    {formatEventDate({
                      eventSchedules: event.eventSchedules,
                      timeframe: event.timeframe,
                      locale,
                    })}
                  </Typography>
                </Stack>
                <Stack spacing={0.25}>
                  <Typography
                    variant="smallTextSemiBold"
                    color="secondary8.main"
                    component="div"
                  >
                    {t('venue')}
                  </Typography>
                  <Typography
                    variant="smallTextSemiBold"
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    component="div"
                  >
                    {formatEventPlace(event.eventPlace, t)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Button variant="primary" size="medium">
              {isEventWithoutTickets ? t('show_details') : t('find_tickets')}
            </Button>
          </Stack>
        </Stack>
      </NextLink>
    </>
  )
}
