import Head from 'next/head'
import { CSSProperties, useMemo } from 'react'
import { theme } from 'src/app/theme'
import { Breakpoint } from 'src/app/theme/breakpoints'
import { EnumType } from 'src/lib/types'

const formatMediaMinWidth = (width: number) => `(min-width:${width}px)`

const formatPreloadMedia = (minWidth: number, maxWidth?: number) =>
  `${formatMediaMinWidth(minWidth)}${
    maxWidth !== undefined ? ` and (max-width:${maxWidth - 1}px)` : ''
  }`

export type SrcSet = Partial<
  Record<Exclude<EnumType<Breakpoint>, 'TABLET_LANDSCAPE'>, string>
>

/* eslint-disable @next/next/no-img-element */
type Props = {
  hasPriority?: boolean
  originalSizeUrl: string
  alt: string
  className?: string
  srcSet: SrcSet
  objectFit?: CSSProperties['objectFit']
}

export const ResponsiveImage = ({
  hasPriority,
  originalSizeUrl,
  alt,
  className,
  srcSet,
  objectFit,
}: Props) => {
  const sources = useMemo(() => {
    const filteredSources = [
      { src: srcSet.BIG_DESKTOP, media: theme.breakpoints.values.xl },
      { src: srcSet.SMALL_DESKTOP, media: theme.breakpoints.values.lg },
      { src: srcSet.TABLET, media: theme.breakpoints.values.sm },
      { src: srcSet.MOBILE, media: theme.breakpoints.values.xs },
    ].filter(({ src }) => src !== undefined)

    return filteredSources.map(({ media, src }, idx) => {
      const nextBreakpoint = filteredSources[idx - 1]

      return {
        src,
        sourceMedia: formatMediaMinWidth(media),
        preloadMedia: formatPreloadMedia(media, nextBreakpoint?.media),
      }
    })
  }, [srcSet.BIG_DESKTOP, srcSet.MOBILE, srcSet.SMALL_DESKTOP, srcSet.TABLET])

  return (
    <>
      {hasPriority && (
        <Head>
          {sources.map((source) => (
            <link
              rel="preload"
              as="image"
              href={source.src}
              media={source.preloadMedia}
              key={source.preloadMedia}
            />
          ))}
        </Head>
      )}
      <picture>
        {sources.map((source) => (
          <source
            media={source.sourceMedia}
            srcSet={source.src}
            key={source.sourceMedia}
          />
        ))}
        <img
          src={originalSizeUrl}
          alt={alt}
          decoding="async"
          className={className}
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            color: 'transparent',
            objectFit: objectFit ?? 'cover',
          }}
          loading={hasPriority ? 'eager' : 'lazy'}
        />
      </picture>
    </>
  )
}
