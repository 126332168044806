import { useSession } from 'next-auth/react'
import { globalAccessToken } from '../use-auth'

export const useIsSessionLoaded = () => {
  const session = useSession()

  return (
    session.status === 'unauthenticated' ||
    (session.status === 'authenticated' && globalAccessToken !== null)
  )
}
