import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { UUID } from 'io-ts-types'
import { addFavoriteEvent } from 'src/api'
import { useFavoriteSignIn } from 'src/modules/favorites/use-favorite-sign-in'

type Props = {
  isFavoriteDefault: boolean
  eventId: UUID
  onSuccessAdd?: () => void
  eventName: string
}

export const useFavoriteEvent = ({
  isFavoriteDefault,
  eventId,
  onSuccessAdd,
  eventName,
}: Props) => {
  const [isFavorite, setIsFavorite] = useState(isFavoriteDefault)

  useEffect(() => {
    setIsFavorite(isFavoriteDefault)
  }, [isFavoriteDefault])

  const $addFavorite = useMutation(addFavoriteEvent)
  const onAddFavoriteEvent = (isChecked: boolean) => {
    setIsFavorite(isChecked)
    $addFavorite.mutate(
      { eventId, favourite: isChecked },
      { onSuccess: onSuccessAdd },
    )
  }
  const { dialogUnauthenticated, onAddFavorite } = useFavoriteSignIn({
    onAddFavorite: onAddFavoriteEvent,
    entity: {
      entityType: 'event',
      id: eventId,
      name: eventName,
    },
  })

  return {
    isFavorite,
    onAddFavorite,
    dialogUnauthenticated,
  }
}
