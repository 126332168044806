import { IImageResizedUrls } from 'src/api'
import { SrcSet } from './responsive-image'

export const getHeaderImageSrcSet = (
  resizedUrls: IImageResizedUrls,
): SrcSet => {
  return {
    SMALL_DESKTOP: resizedUrls.LARGE,
    MOBILE: resizedUrls.MEDIUM,
  }
}

export const getLogoSrcSet = (resizedUrls: IImageResizedUrls): SrcSet => {
  return {
    MOBILE: resizedUrls.LARGE,
  }
}
