import { Checkbox } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx'
import { LikeCheckedIcon, LikeIcon } from 'src/assets/icons'

type Props = {
  isFavorite: boolean
  onClick: (isFavorite: boolean) => void
  color?: string
  iconProportion: ResponsiveStyleValue<number>
  isFavoriteLoading: boolean
  buttonProportion: ResponsiveStyleValue<number>
}

export const actionButtonBackground = {
  backgroundColor: 'rgba(255, 255, 255, 0.35)',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  transition: '250ms',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.45)',
  },
  '&:active': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  '.MuiTouchRipple-root': {
    display: 'none',
  },
} as const

export const AddToFavorites = ({
  isFavorite,
  onClick,
  color,
  iconProportion,
  isFavoriteLoading,
  buttonProportion,
}: Props) => {
  if (isFavoriteLoading) {
    return null
  }

  return (
    <Checkbox
      checked={isFavorite}
      icon={
        <LikeIcon
          sx={{
            color: color ?? 'secondary8.main',
            fontSize: iconProportion,
          }}
        />
      }
      checkedIcon={<LikeCheckedIcon sx={{ fontSize: iconProportion }} />}
      onClick={(event) => {
        event.stopPropagation()
      }}
      onChange={(event) => {
        onClick(event.target.checked)
      }}
      sx={{
        width: buttonProportion,
        height: buttonProportion,
      }}
    />
  )
}
