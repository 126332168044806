import {
  Grid,
  Stack,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Chip,
} from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode } from 'react'
import { IEvent, EventStatus } from 'src/api'
import { EnumType } from 'src/lib/types'
import { useLocale } from 'src/lib/use-locale'
import { NextLink } from 'src/components/next-link'
import { pages } from 'src/app/pages'
import { formatEventPlace } from '../utils/event-format-display'
import { formatEventDate } from '../utils/event-format-date'
import { ResponsiveImage, getLogoSrcSet } from 'src/components/responsive-image'
import { isSome, toNullable } from 'fp-ts/lib/Option'

const textOverflowProps = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const

export type EventCardWithoutTagsProps = {
  event: IEvent
}

type Props = EventCardWithoutTagsProps & {
  additionalInfoRight: ReactNode
  href?: string
  isShowStatus?: boolean
}

export const EventCardWithoutTagsBase = ({
  event,
  additionalInfoRight,
  href,
  isShowStatus,
}: Props) => {
  const { t } = useTranslation()
  const { locale } = useLocale()

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid item xs={12} sm={6} md={4}>
      <NextLink
        href={
          href ??
          pages['/events/[eventSlug]']({
            eventSlug: event.eventSlug,
          })
        }
        underline="none"
        color="common.black"
      >
        <Stack
          direction={{ xs: 'row', sm: 'column' }}
          sx={{
            backgroundColor: 'common.white',
            boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.15)',
            borderRadius: '6px',
            transition: 'transform 0.3s',
            willChange: 'transform',
            '&:hover': {
              transform: 'scale(1.03)',
            },
          }}
        >
          <Box
            sx={{
              height: { xs: 157, sm: 148, lg: 180, xl: 212 },
              width: { xs: 98, sm: '100%' },
              position: 'relative',
              flexShrink: 0,
              '& .eventImage': {
                borderTopLeftRadius: '6px',
                borderTopRightRadius: { xs: 0, sm: '6px' },
                borderBottomLeftRadius: { xs: '6px', sm: 0 },
                backgroundColor:
                  toNullable(event.logoSetting)?.logoBackgroundColor ??
                  undefined,
              },
            }}
          >
            {isSome(event.logoSetting) ? (
              <ResponsiveImage
                srcSet={getLogoSrcSet(event.logoSetting.value.logo.resizedUrls)}
                alt={event.name}
                originalSizeUrl={event.logoSetting.value.logo.url}
                className="eventImage"
                objectFit="contain"
              />
            ) : (
              <ResponsiveImage
                srcSet={{
                  MOBILE: event.mainImage.resizedUrls.SMALL,
                  TABLET: event.mainImage.resizedUrls.MEDIUM,
                }}
                alt={event.name}
                originalSizeUrl={event.mainImage.url}
                className="eventImage"
              />
            )}
            {isShowStatus && <EventStatusChip status={event.status} />}
          </Box>
          <Stack
            p={{ xs: 1.5, lg: 2, xl: 2.5 }}
            pb={{ sm: 2, lg: 2.5, xl: 3 }}
            spacing={{ xs: 1, xl: 1.5 }}
            sx={{ minWidth: 0, width: '100%' }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={0.25} minWidth={0}>
                <Typography
                  variant="tinyTextSemiBold"
                  sx={{
                    ...textOverflowProps,
                  }}
                >
                  {event.eventType.name}
                </Typography>
                <Typography
                  component="div"
                  variant={isTablet ? 'h3ExtraBold' : 'h3Bold'}
                  sx={{
                    ...textOverflowProps,
                  }}
                >
                  {event.name}
                </Typography>
              </Stack>
              {additionalInfoRight}
            </Stack>
            <Stack spacing={{ xs: 1 }}>
              <Stack spacing={0.25} alignItems="flex-start">
                <Typography
                  variant="smallTextSemiBold"
                  color="secondary8.main"
                  component="div"
                >
                  {t('date')}
                </Typography>
                <Typography variant="smallTextSemiBold" component="div">
                  {formatEventDate({
                    eventSchedules: event.eventSchedules,
                    timeframe: event.timeframe,
                    locale,
                  })}
                </Typography>
              </Stack>
              <Stack spacing={0.25} alignItems="flex-start">
                <Typography
                  variant="smallTextSemiBold"
                  color="secondary8.main"
                  component="div"
                >
                  {t('venue')}
                </Typography>
                <Typography
                  variant="smallTextSemiBold"
                  sx={{
                    ...textOverflowProps,
                    width: '100%',
                    textAlign: 'left',
                  }}
                  component="div"
                >
                  {formatEventPlace(event.eventPlace, t)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </NextLink>
    </Grid>
  )
}

type EventStatusChipProps = {
  status: EnumType<EventStatus>
}

const EventStatusChip = ({ status }: EventStatusChipProps) => {
  const { t } = useTranslation()

  return (
    <Chip
      variant="status"
      label={status === EventStatus.CANCELLED ? t('cancelled') : t('completed')}
      sx={{
        backgroundColor:
          status === EventStatus.CANCELLED
            ? 'primary2.main'
            : 'secondary2.main',
        zIndex: 1,
        position: 'absolute',
        top: { xs: 'auto', sm: 12, lg: 16 },
        right: { xs: 'auto', sm: 12, right: 16 },
        bottom: { xs: 12, sm: 'auto' },
        left: { xs: '50%', sm: 'auto' },
        transform: { xs: 'translateX(-50%)', sm: 'none' },
      }}
    />
  )
}
