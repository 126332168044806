import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray'
import { EventTimeframe, IEventSchedule } from 'src/api'
import { IApplicationLocale } from 'src/app/locale'
import {
  formatDateRange,
  formatInTimeZoneLocalized,
  formatTemplateDayOfWeekDayMonthYear,
  formatTemplateDayOfWeekDayMonthYearTime,
} from 'src/lib/format'
import { EnumType } from 'src/lib/types'

export const formatEventDate = ({
  eventSchedules,
  timeframe,
  locale,
}: {
  eventSchedules: NonEmptyArray<IEventSchedule>
  timeframe: EnumType<EventTimeframe>
  locale: IApplicationLocale
}) => {
  const eventSchedule = eventSchedules[0]
  const timezone = eventSchedule.timezone

  return timeframe === EventTimeframe.ONE_DAY
    ? formatInTimeZoneLocalized({
        date: eventSchedule.startDate,
        locale,
        timezone,
        format: formatTemplateDayOfWeekDayMonthYearTime[locale] ?? '',
      })
    : formatDateRange({
        startDate: eventSchedule.startDate,
        endDate: eventSchedule.endDate,
        timezone,
        locale,
      })
}

export const formatEventDateWithoutTime = ({
  eventSchedules,
  timeframe,
  locale,
}: {
  eventSchedules: NonEmptyArray<IEventSchedule>
  timeframe: EnumType<EventTimeframe>
  locale: IApplicationLocale
}) => {
  const eventSchedule = eventSchedules[0]
  const timezone = eventSchedule.timezone

  return timeframe === EventTimeframe.ONE_DAY
    ? formatInTimeZoneLocalized({
        date: eventSchedule.startDate,
        locale,
        timezone,
        format: formatTemplateDayOfWeekDayMonthYear[locale] ?? '',
      })
    : formatDateRange({
        startDate: eventSchedule.startDate,
        endDate: eventSchedule.endDate,
        timezone,
        locale,
      })
}
