import { Translate } from 'next-translate'
import { EventPlace, IEventDetails, IEventPlace, IVenue } from 'src/api'
import { formatCityCountry } from 'src/lib/format'

export const formatEventVenue = (venue: IVenue) =>
  `${venue.name}, ${formatCityCountry({
    city: venue.city,
    country: venue.country,
  })}`

export const formatEventPlace = (eventPlace: IEventPlace, t: Translate) =>
  eventPlace.placeType === EventPlace.TO_BE_ANNOUNCED
    ? t('somewhere_in', { city: eventPlace.announcedCity })
    : formatEventVenue(eventPlace.venue)

export const formatEventCityCountry = (event: IEventDetails) => {
  const place = event.eventPlace
  const { city, country } =
    place.placeType === EventPlace.TO_BE_ANNOUNCED
      ? { city: place.announcedCity, country: event.country }
      : { city: place.venue.city, country: place.venue.country }

  return formatCityCountry({ city, country })
}
