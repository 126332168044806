// Enable horizontal scroll on full screen width inside Container. Numbers inside p, m are Container paddings. Vertical margin and padding are needed to display shadows correctly
export const scrollableRowStyles = {
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  p: { xs: 2, sm: 3.5, md: 6 },
  m: { xs: -2, sm: -3.5, md: -6 },
} as const
