import { AnalyticEntity, sendAddToFavoritesAnalytics } from 'src/app/analytics'
import { useAuthenticationRequired } from 'src/modules/sign-in/dialog-sign-in'

type Props = {
  onAddFavorite: (isChecked: boolean) => void
  entity: AnalyticEntity
}

export const useFavoriteSignIn = ({ onAddFavorite, entity }: Props) => {
  const onClickAddFavorite = (isChecked: boolean) => {
    if (isChecked) {
      sendAddToFavoritesAnalytics(entity)
    }
    onAddFavorite(isChecked)
  }

  const { dialogUnauthenticated, onClickAuthenticationRequired } =
    useAuthenticationRequired({ authorizedAction: onClickAddFavorite })

  return {
    dialogUnauthenticated,
    onAddFavorite: onClickAuthenticationRequired,
  }
}
