import { useIsSessionLoaded } from 'src/lib/use-is-session-loaded'
import { AddToFavorites } from 'src/modules/favorites/add-favorite'
import { DialogSignIn } from 'src/modules/sign-in/dialog-sign-in'
import { useFavoriteEvent } from '../use-favorite'
import {
  EventCardWithoutTagsBase,
  EventCardWithoutTagsProps,
} from './event-card-without-tags-base'

type Props = EventCardWithoutTagsProps & {
  isOnlyRemove?: boolean
  onSuccessAddFavorite?: () => void
  isShowStatus?: boolean
}

export const EventCardWithoutTags = ({
  event,
  isOnlyRemove,
  onSuccessAddFavorite,
  isShowStatus,
}: Props) => {
  const isSessionLoaded = useIsSessionLoaded()
  const { isFavorite, dialogUnauthenticated, onAddFavorite } = useFavoriteEvent(
    {
      eventId: event.eventId,
      isFavoriteDefault: event.favourite,
      onSuccessAdd: onSuccessAddFavorite,
      eventName: event.name,
    },
  )

  return (
    <>
      {dialogUnauthenticated.isTrue && (
        <DialogSignIn onClose={dialogUnauthenticated.setFalse} />
      )}
      <EventCardWithoutTagsBase
        event={event}
        isShowStatus={isShowStatus}
        additionalInfoRight={
          <AddToFavorites
            isFavorite={isFavorite}
            onClick={(isChecked) => {
              onAddFavorite(isOnlyRemove ? false : isChecked)
            }}
            isFavoriteLoading={!isSessionLoaded}
            iconProportion={{ xs: 24, lg: 28 }}
            buttonProportion={{ xs: 24, lg: 28 }}
          />
        }
      />
    </>
  )
}
