import { ReactNode } from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { sectionHeaderContentSpacing } from 'src/layouts/spacing'

type Props = {
  title: string
  children: ReactNode
  withDivider?: boolean
}

export const DetailsSection = ({
  title,
  children,
  withDivider = true,
}: Props) => {
  return (
    <>
      {withDivider && <Divider flexItem />}
      <Stack spacing={sectionHeaderContentSpacing}>
        <Typography variant="h2Black" component="h3">
          {title}
        </Typography>
        {children}
      </Stack>
    </>
  )
}
