import { format, getMonth, getYear } from 'date-fns'
import {
  ApplicationLocale,
  IApplicationLocale,
  localeToDateFnsLocale,
} from 'src/app/locale'
import { utcToZonedTime } from 'date-fns-tz'

export const formatTemplateDayMonthYear = {
  [ApplicationLocale.en]: 'd MMM yyy',
  [ApplicationLocale.no]: "d'.' MMM yyy",
}

export const formatTemplateDayOfWeekDayMonthYear = {
  [ApplicationLocale.en]: 'EEE d MMM yyy',
  [ApplicationLocale.no]: "EEE'.' d'.' MMMM yyy",
}

export const formatTemplateDayOfWeekDayMonthYearTime = {
  [ApplicationLocale.en]: 'EEE d MMM yyy, HH:mm',
  [ApplicationLocale.no]: "EEE'.' d'.' MMMM yyy 'kl.' HH:mm",
}

export const formatTemplateDayMonth = {
  [ApplicationLocale.en]: 'd MMM',
  [ApplicationLocale.no]: "d'.' MMM",
}

export const formatTemplateDayMonthYearTime = {
  [ApplicationLocale.en]: 'd MMM yyy, HH:mm',
  [ApplicationLocale.no]: "d'.' MMMM yyy 'kl.' HH:mm",
}

export const formatTemplateDay = {
  [ApplicationLocale.en]: 'd',
  [ApplicationLocale.no]: 'd',
}

export const mapStringToDate = (date: string) => {
  const [year, month, day] = date.split('-')
  if (year === undefined || month === undefined || day === undefined) {
    return null
  }
  return new Date(Number(year), Number(month) - 1, Number(day))
}

export const getFormatStartDateInRange = ({
  startDate,
  endDate,
  locale,
}: {
  startDate: Date
  endDate: Date
  locale: IApplicationLocale
}) => {
  if (getYear(startDate) === getYear(endDate)) {
    if (getMonth(startDate) === getMonth(endDate)) {
      return formatTemplateDay[locale]
    }

    return formatTemplateDayMonth[locale]
  }

  return formatTemplateDayMonthYear[locale]
}

export const formatDateRange = ({
  startDate,
  endDate,
  locale,
  timezone,
}: {
  startDate: string
  endDate: string
  locale: IApplicationLocale
  timezone: string | null
}) => {
  const rangeStartDate =
    timezone === null
      ? new Date(startDate)
      : utcToZonedTime(startDate, timezone)
  const rangeEndDate = new Date(endDate)

  const formatTemplateStartDate = getFormatStartDateInRange({
    startDate: rangeStartDate,
    endDate: rangeEndDate,
    locale,
  })

  const formattedStartDate = format(rangeStartDate, formatTemplateStartDate, {
    locale: localeToDateFnsLocale[locale],
  })

  const formattedEndDate = format(
    rangeEndDate,
    formatTemplateDayMonthYear[locale],
    {
      locale: localeToDateFnsLocale[locale],
    },
  )

  if (
    getYear(rangeStartDate) === getYear(rangeEndDate) &&
    getMonth(rangeStartDate) === getMonth(rangeEndDate)
  ) {
    return `${formattedStartDate}-${formattedEndDate}`
  }

  return `${formattedStartDate} - ${formattedEndDate}`
}
